export const API_ROUTES = {
  LOGIN: "/cms/tech/api/login/",
  FUELS: "/dev/idp/fuel_test/",
  REALTIME: "/dev//idp/realtime/",
  DEVICE_SUMMARY: "/dev/idp/summary/",
  DEVICE_FUEL_SUMMARY: "/dev/idp/fuelsummary/",
  MODEL: "/dev/idp/fuelmodel/",
  FUEL_CULPRIT: "/dev/idp/fuelculprit/",
  DEVICE_TICKET: "/dev/idp/ticket/",
  APP: "/dev/idp/app/",
  INSTALLATION: "/dev/idp/inst_attempts/",
  GSM_GPS: "/dev/idp/gsmgps/",
  INSTALLATION_IMAGE: "/dev/idp/inst_img/",
  FIREBASE: "/cms/check_presence/",
  THRESHOLD: "/dev/idp/vbat_thresh/",
};
